
import $ from "jQuery";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

const accordion = (() => {
  const defaultAccordion = () => {
    const accordionItem = document.getElementsByClassName('js-trigger-accordion');

    for (let i = 0; i < accordionItem.length; i += 1) {
      accordionItem[i].addEventListener('click', function (event) {
        event.currentTarget.classList.toggle('js-active');
        const accordionContent = this.nextElementSibling;
        event.currentTarget.querySelector('.c-accordion__icon svg').classList.toggle('js-active');
        accordionContent.classList.toggle('js-active');
      });
    }
  };


  const init = () => {
    defaultAccordion();
  };

  return {
    init,
  };
})();

export default accordion;
