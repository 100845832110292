/**
 * Tab Component
 *
 * @file tab.js
 */

 import $ from "jQuery";
import AOS from 'aos';
import 'aos/dist/aos.css';
const tabs = (() => {
  /**
   * Default Tab
   * Project default tab style
   */
  const defaultTab = () => {
      $(".tab-lnk a").on("click", function(){
        $(".tab-lnk a").removeClass("active");
        $(this).addClass("active");
        $(".tab-dd-").removeClass("active");
        var thLk = $(this).attr("href");
        $(thLk).addClass("active");
        $(".tab-dd-").find(".video-bg").get(0).pause();
        $(".tab-dd-").find(".video-bg").get(0).currentTime = 0;
        $(thLk).find(".video-bg").get(0).play();
        return false;
      });
  };

  const Aos = () => {
    AOS.init();
  };

  const init = () => {
    defaultTab();
    Aos();
  };

  return {
    init,
  };
})();

export default tabs;
