/**
 * Header Layout
 *
 * @file header.js
 */

import $ from "jQuery";

const header = (() => {
  const menuWidth = () => {
    var mainMenu1 = $('.main-nav').eq(0),
    mainMenu1Width = mainMenu1.innerWidth(),
    mainMenu2 = $('.main-nav').eq(1),
    mainMenu2Width = mainMenu2.innerWidth();
    function menuSize(){
      if(mainMenu1 >= mainMenu2){
        mainMenu1.removeAttr("style");
        mainMenu2.removeAttr("style");
        var mainMenu1Width = mainMenu1.innerWidth();
        var mainMenu2Width = mainMenu1.innerWidth();
        mainMenu2.css("width",mainMenu1Width+"px");
      } else{
        mainMenu1.removeAttr("style");
        mainMenu2.removeAttr("style");
        var mainMenu1Width = mainMenu1.innerWidth();
        var mainMenu2Width = mainMenu1.innerWidth();
        mainMenu1.css("width",mainMenu2Width+"px");
      }
    }
    function windowResizeMenu(){
      if (window.innerWidth <= 1430) { 
        menuSize()
      }
      if(window.innerWidth <= 1200){
        menuSize()
      }   
    }
    menuSize();
    $(window).resize(function(){
      windowResizeMenu();
    });
  };

  
  const openMenu = () => {
    $("header .main-nav .main-nav__item").hover(function(){
      $(this).find(".sub-menus").show();
    }, function(){
      $(this).find(".sub-menus").hide();
    });
    $("header .sub-menus li").hover(function(){
      $(this).find(".last-sub-menu").show();
    }, function(){
      $(this).find(".last-sub-menu").hide();
    });
  };

  const fullSearch = () => {
    $("header .right .search-icon, .mobile-search").on("click", function(){
      $(".full-search").toggleClass("active");
      $("html").toggleClass("hide");
      setTimeout(function(){ 
        $(".full-search #query").focus();
      }, 500);
    });
    $(".full-search .bg").on("click", function(){
      $(".full-search").removeClass("active");
      $("html").removeClass("hide");
    });
  };

  const mobileMenu = () => {
    $(".mobile-menu-icon").on("click", function(){
      $(this).toggleClass("active");
      $(".mobile-menu-box").toggleClass("active");
    });
    if($(window).width() < 992){
      $(".left-menu-title").on("click", function(){
        $(this).toggleClass("active");
        $(this).next(".lft-mn").slideToggle();
      });
    }
    $("li.sub-menu").on("click", function(){
      $(this).find(".sub-menu-item").show();
    });
  };

  const init = () => {
    menuWidth();
    openMenu();
    fullSearch();
    mobileMenu();
  };

  return {
    init,
  };
})();

export default header;
