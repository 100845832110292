/**
 * Slider Component
 *
 * @file slider.js
 */

import $ from "jQuery";
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectCoverflow, EffectFade, Controller } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow, EffectFade, Controller]);

import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.js';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';

const slider = (() => {
  /**
   * fullSlider
   * Full width slider settings
   */
  const fullSlider = () => {
    
    const swiper2 = new Swiper(".home-main-slider", {
      spaceBetween: 0,
      slidesPerView: 1,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      allowTouchMove: false,
      effect: 'fade',
    });

    const swiper = new Swiper(".home-main-thumb-slider", {
      spaceBetween: 0,
      slidesPerView: 1,
      mousewheel: false,
      watchOverflow: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      preventInteractionOnTransition: false,
      allowTouchMove:false,
      // thumbs: {
      //   swiper: swiper2
      // },
      navigation: {
        nextEl: ".home-th-next",
        prevEl: ".home-th-prev",
      },
      pagination: {
        el: ".home-th-pagination",
        type: "progressbar",
      },
      on: {
        init: function (el) {
          $(".home-all-slide").html(el.slides.length);
          $(".home-active-slide").html(el.activeIndex + 1);
        },
      },
    });
    swiper.on('slideChange', function () {
      $(".home-active-slide").html(swiper.activeIndex + 1);
      swiper2.slideTo(swiper.activeIndex);
    });

  };

  const homeNewsSlider = () => {
    const swiperNews = new Swiper('.home-news-carousel', {
      spaceBetween: 30,
      slidesPerView: 2,
      loop:true,
      navigation: {
        nextEl: '.home-news-next',
        prevEl: '.home-news-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        767: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    });
  }

  const init = () => {
    fullSlider();
    homeNewsSlider();
  };

  return {
    init,
  };
})();

export default slider;
