/**
 * Tab Component
 *
 * @file tab.js
 */

import $ from 'jquery';
// import jqueryvalidation from 'jquery-validation';
// import 'jquery-validation/dist/jquery.validate.min.js';


const contactForm = (() => {
  /**
   * Default Tab
   * Project default tab style
   */
  const formValidate = () => {
		// $("#contactForm").validate();
    $(".contact-form .form .inp").each(function(){
      $(this).find("label").on("click", function(){
        $(this).prev("input").focus();
        $(this).prev("textarea").focus();
      });
      $(this).find("input, textarea").keyup(function(){
        if($(this).val() != " " && $(this).val() != ""){
          $(this).addClass("show-text");
        }
      });
    });
  };
  
  const careerForm = () => {
    $('.custom-file-input').change(function(e){
        var fileName = e.target.files[0].name;
        $(".file-name").html(fileName)
    });
  };

  const init = () => {
    formValidate();
    careerForm();
  };

  return {
    init,
  };
})();

export default contactForm;
